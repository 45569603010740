<template>
	<div>
		<div
			v-if="review && !loading"
			:class="{ 'h-100': review.attributes.comment === null }"
			class="d-flex">
			<div
				class="d-flex flex-column align-items-center justify-content-center"
				style="width: 100px; min-width: 100px;">
				<img
					loading="lazy"
					:src="review.attributes.product.image"
					alt="Product Image"
					class="responsive block-inline"
					style="max-width:56px">
				<p class="m-0 text-center px-2">
					{{ translate(review.attributes.product.code_name) }}
				</p>
			</div>
			<div class="h-100 d-flex align-items-center">
				<article>
					<h5 class="m-0">
						{{ review.attributes.user.shortened_name }}
						<span class="badge badge-primary">{{ translate(review.attributes.hidden ? 'hidden' : 'visible') }}</span>
					</h5>
					<div class="d-flex align-items-center flex-wrap">
						<div class="d-flex justify-content-start">
							<b-rating
								:value="review.attributes.rating"
								variant="primary"
								style="padding-left: 0; padding-top: 0; padding-bottom: 0"
								inline
								no-border
								show-value-max
								show-value
								readonly />
						</div>
						<span
							class="text-muted text-medium"
							style="margin-right: 35px">
							{{ review.attributes.created_at.date ? $moment(review.attributes.created_at.date).format(dateFormat) : $moment(review.attributes.created_at).format(dateFormat) }}
						</span>
					</div>
					<h6>{{ review.attributes.title }}</h6>
					<p class="m-0">
						{{ review.attributes.comment }}
					</p>
					<p
						v-if="review.attributes.edited"
						class="m-0 text-muted text-medium"
						style="text-transform: lowercase">
						({{ translate('edited') }})
					</p>
				</article>
			</div>
		</div>
		<h3
			v-else
			class="fa fa-fw fa-spinner fa-pulse" />
		<hr>
		<div class="d-flex justify-content-between mb-2">
			<h3>{{ translate('reports') }}</h3>
			<b-button
				v-if="review && $can('product_reviews', 'hide')"
				variant="primary"
				class="text-nowrap"
				@click="hide(review)">
				{{ translate(review.attributes.hidden ? 'show_review' : 'hide_review') }}
				<i
					v-if="loadingVisibility"
					class="fa fa-fw fa-spinner fa-pulse" />
			</b-button>
		</div>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="text-center">
							{{ translate('user_id') }}
						</th>
						<th>{{ translate('user') }}</th>
						<th style="min-width: 300px">
							{{ translate('report_reason') }}
						</th>
						<th>{{ translate('created_at') }}</th>
					</tr>
				</thead>
				<tbody v-if="!reportsLoading">
					<tr
						v-for="report in reviewReports"
						:key="report.id">
						<td class="align-middle text-center">
							<span
								class="badge badge-primary">
								{{ report.attributes.user.id }}
							</span>
						</td>
						<td class="align-middle">
							{{ report.attributes.user.full_name }}
						</td>
						<td
							class="align-middle"
							style="min-width: 300px; white-space: pre-wrap">
							{{ report.attributes.reason }}
						</td>
						<td class="align-middle">
							{{ report.attributes.created_at.date ? $moment(report.attributes.created_at.date).format(dateFormat) : $moment(report.attributes.created_at).format(dateFormat) }}
						</td>
					</tr>
				</tbody>
			</table>
			<is-loading
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="reportsLoading"
				:has-data="hasReports" />
			<b-pagination
				v-if="pagination.total_pages > 1"
				v-model="pagination.current_page"
				:total-rows="pagination.total"
				:per-page="pagination.per_page"
				align="center"
				@change="getReviewReports" />
		</div>
	</div>
</template>

<script>
import ProductReviews from '@/util/ProductReviews';
import { MMMDY_FORMAT } from '@/settings/Dates';
import { Products, ProductReviews as ProductReviewsMessages, Grids } from '@/translations';

export default {
	name: 'ReviewReports',
	messages: [Grids, Products, ProductReviewsMessages],
	data() {
		return {
			reviews: new ProductReviews(),
			reports: new ProductReviews(),
			visibility: new ProductReviews(),
			dateFormat: MMMDY_FORMAT,
			alert: new this.$Alert(),
		};
	},
	computed: {
		review() {
			try {
				const { data } = this.reviews.data.response.data;
				return data;
			} catch (error) {
				return null;
			}
		},
		reviewReports() {
			try {
				const { data } = this.reports.data.response.data;
				return data;
			} catch (error) {
				return null;
			}
		},
		pagination() {
			return this.reports.data.pagination;
		},
		loading() {
			return !!this.reviews.data.loading;
		},
		reportsLoading() {
			return !!this.reports.data.loading;
		},
		hasReports() {
			const response = this.reviewReports?.length ?? 0;
			return !!response;
		},
		loadingVisibility() {
			return !!this.visibility.data.loading;
		},
		reviewId() {
			const { reviewId } = this.$route.params;
			return reviewId;
		},
	},
	created() {
		this.getReview(this.reviewId);
		this.getReviewReports(1);
	},
	methods: {
		getReview() {
			this.reviews.getReviewById(this.reviewId);
		},
		getReviewReports(page) {
			this.reports.getReviewReports(this.reviewId, page);
		},
		hide(item) {
			this.visibility.hideProductReview(item.id, item.attributes.hidden ? 0 : 1)
				.then(() => {
					this.alert.toast('success', this.translate('successfully_updated'));
					this.getReview();
				})
				.catch(() => {
					this.alert.toast('error', this.translate('default_error_message'));
				});
		},
	},
};
</script>

<style scoped>

</style>
